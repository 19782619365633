import shopifyGenericDefaults from '../shopify-generic/config.js';

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter(
      (w) => !['SearchBox', 'SearchBoxDialogButton', 'HeaderVehicleWidget'].includes(w.name),
    ),
    {
      name: 'SearchBox',
      location: { replace: '.header-search-global-form' },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        firstChildOf: '.head-row-slot-items',
        class: 'head-slot-global-search-link',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 990,
      fields:
        !!window.Convermax.config?.extraFieldsAsFilters && shopifyGenericDefaults.fitmentSearch.baseFields,
    },
  ],
};
