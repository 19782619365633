export default {
  BUTTON_PRIMARY_CLASS: 'button button-secondary',
  BUTTON_SECONDARY_CLASS: 'button button-outline',

  GARAGE_SIZE: '<span class="garage-size head-slot-cart-link-quantity" key="garage-size">{{size}}</span>',

  SELECT_YOUR_VEHICLE: 'CHOOSE YOUR VEHICLE',
  SELECT_YOUR_VEHICLE_BLOCK: 'CHOOSE YOUR VEHICLE',
  VEHICLE_WIDGET_HOME_CLASS: 'container',

  PRODUCT_REPEATER_CLASS: 'grid grid-3-col-tablet grid-5-col-desktop grid-compact',
};
